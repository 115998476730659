import { motion } from "framer-motion";
import { join, size, slice, split } from "lodash";
import * as React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { EditSizes } from "../../components";
import { useAuth, useDatabase } from "../../contexts";
import "./styles.scss";
interface IProfilePageProps {}

const ProfilePage: React.FunctionComponent<IProfilePageProps> = (props) => {
  const [editSizes, setEditSizes] = useState<boolean>(false);

  const { currentUser } = useAuth();
  const { userData } = useDatabase();

  const user_name = split(currentUser?.displayName, " ");
  const user_first_name = user_name[0];
  const user_last_name = join(slice(user_name, 1, size(user_name)), " ");
  const history = useHistory();

  return (
    <div className="profile-page">
      <div className="personal-details">
        <div className="profile-picture-container">
          <div
            className="profile-picture"
            style={{
              background: `url('${userData?.photoUrl}') no-repeat center/110%`,
            }}
          />
          <div className="details">
            <div className="name">
              <strong>{user_first_name}</strong> {user_last_name}
            </div>
            <motion.div
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.98 }}
              style={{ width: "100%" }}
            >
              <Link to="/create-avatar" className="submit edit-avatar">
                Edit avatar
              </Link>
            </motion.div>
          </div>
        </div>
        <div className="details">
          <div className="labels">
            <div className="label">Personal Details:</div>
          </div>
          <div className="details-list">
            <div>{currentUser?.displayName}</div>
            <div>{currentUser?.email}</div>
            <div>***********</div>
          </div>
        </div>

        <motion.div whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.98 }}>
          <Link to="/wardrobe/profile/edit-details" className="submit">
            Change/edit details
          </Link>
        </motion.div>
      </div>
      <div className="edit-sizes">
        <div className="current-sizes">
          <div>
            <strong>{userData?.waistSize}</strong> WAIST
          </div>
          <div>
            <strong>{userData?.shirtSize}</strong> shirt
          </div>
        </div>
        <motion.div
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.98 }}
          style={{ width: "100%" }}
        >
          <button
            className="submit"
            onClick={(e) => {
              e.preventDefault();
              setEditSizes(true);
            }}
          >
            Edit sizes
          </button>
        </motion.div>
        {editSizes && (
          <EditSizes
            setEditSizes={setEditSizes}
            profileWaistSize={userData?.waistSize}
            profileShirtSize={userData?.shirtSize}
          />
        )}
      </div>
      <motion.div
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.98 }}
        className="links"
      >
        <Link to="/wardrobe" className="browse-wardrobe">
          browse wardrobe
        </Link>
      </motion.div>
      <motion.div
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.95 }}
        className="links"
      >
        <a
          href="/"
          className="back-button"
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
        >
          back
        </a>
      </motion.div>
    </div>
  );
};

export default ProfilePage;
