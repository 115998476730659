import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  LoaderComponent,
  OurMkmTextHeader,
  SignInWithProviderComponent,
  SubmitButton,
} from '../../../components';
import { useAuth } from '../../../contexts/auth-context';
import { signUpSchema } from '../../../utilities';
import { SignUpInterface } from '../../../utilities/interfaces';
import IconLock from '../../../assets/icons/icon-lock.png';
import IconEnvelop from '../../../assets/icons/icon-envelop.png';
import FormFeedBack from '../../../components/form-feedback';

interface ISignUpScreenProps {}

const SignUpScreen: React.FunctionComponent<ISignUpScreenProps> = (props) => {
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { signUp } = useAuth();

  const handleFormSubmit = async (values: SignUpInterface) => {
    setLoading(true);
    try {
      await signUp(values);
    } catch (err) {
      setLoading(false);
      setError(err);
      formInk.isSubmitting = false;
    }
  };

  const initialValues: SignUpInterface = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const formInk = useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema,
    validateOnMount: false,
    onSubmit: (values: SignUpInterface) => {
      handleFormSubmit(values);
      formInk.isSubmitting = true;
    },
  });

  const { errors, touched, isValid, dirty, submitForm } = formInk;

  if (loading) return <LoaderComponent />;

  return (
    <div className='sign-up-screen'>
      <FormikProvider value={formInk}>
        <div className='form'>
          <div className='sign-up-header'>
            <OurMkmTextHeader />
            <div className='logo-outline-container'>
              <img
                src={
                  require('../../../assets/icons/markham-logo-outline-shadow.png')
                    .default
                }
                alt='Markham Logo'
              />
            </div>
          </div>
          <Form>
            <div className='sign-up-heading'>sign up:</div>
            <div className='form-group'>
              <div className='form-field'>
                <Field
                  type='text'
                  name='name'
                  id='name'
                  placeholder='name:'
                  onFocus={() => {
                    if (error !== null) setError(null);
                  }}
                  className={errors.name && touched.name ? 'input-error' : null}
                />
                <div className='icon'>
                  <img src={IconEnvelop} alt='' />
                </div>
              </div>
              <ErrorMessage name='name' component='span' className='error' />
            </div>
            <div className='form-group'>
              <div className='form-field'>
                <Field
                  type='email'
                  name='email'
                  id='email'
                  placeholder='email:'
                  onFocus={() => {
                    if (error !== null) setError(null);
                  }}
                  className={
                    errors.email && touched.email ? 'input-error' : null
                  }
                />
                <div className='icon'>
                  <img src={IconEnvelop} alt='' />
                </div>
              </div>
              <ErrorMessage name='email' component='span' className='error' />
            </div>
            <div className='form-group'>
              <div className='form-field'>
                <Field
                  type='password'
                  name='password'
                  id='password'
                  placeholder='password:'
                  onFocus={() => {
                    if (error !== null) setError(null);
                  }}
                  className={
                    errors.password && touched.password ? 'input-error' : null
                  }
                />
                <div className='icon'>
                  <img src={IconLock} alt='' />
                </div>
              </div>
              <ErrorMessage
                name='password'
                component='span'
                className='error'
              />
            </div>
            <div className='form-group'>
              <div className='form-field'>
                <Field
                  type='password'
                  name='confirmPassword'
                  id='confirmPassword'
                  placeholder='confirm password:'
                  onFocus={() => {
                    if (error !== null) setError(null);
                  }}
                  className={
                    errors.confirmPassword && touched.confirmPassword
                      ? 'input-error'
                      : null
                  }
                />
                <div className='icon'>
                  <img src={IconLock} alt='' />
                </div>
              </div>
              <ErrorMessage
                name='confirmPassword'
                component='span'
                className='error'
              />
            </div>
            <div className='form-group'>
              <SubmitButton
                title='sign up'
                cssClass='form-submit sign-button'
                disabled={!(dirty && isValid && !loading)}
                loading={loading}
                action={submitForm}
              />
              <FormFeedBack errorMessage={error} successMessage='' />
            </div>

            <SignInWithProviderComponent
              setLoading={setLoading}
              setError={setError}
            />
            <Link to='/auth' className='back-link'>
              Sign In
            </Link>
          </Form>
        </div>
      </FormikProvider>
    </div>
  );
};

export default SignUpScreen;
