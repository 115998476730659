import React, { useEffect } from "react";
import GoogleLogin from "react-google-login";
import { useAuth } from "../../contexts";
import FacebookLogin from "react-facebook-login";
import { useLocation } from "react-router-dom";
import { includes } from "lodash";

interface ISignInWithProviderComponentProps {
  setLoading: Function;
  setError: Function;
}

const SignInWithProviderComponent: React.FunctionComponent<ISignInWithProviderComponentProps> =
  ({ setLoading, setError }) => {
    const facebookAppId: string = process.env
      .REACT_APP_FACEBOOK_APP_ID as string;
    const googleAppId: string = process.env
      .REACT_APP_GOOGLE_CLIENT_ID as string;
    const googleClientId: string = process.env
      .REACT_APP_GOOGLE_CLIENT_ID as string;
    const siteId: string = process.env.REACT_APP_MKM_SITE_ID as string;

    const { signInWithProvider, signUpWithProvider } = useAuth();
    const { pathname } = useLocation();

    const handleFacebookLogin = async (response: any) => {
      if (response.status === "unknown") return;
      console.log("facebook login");

      setLoading(true);
      const { accessToken, email, id } = response;

      if (includes(pathname, "sign-up")) {
        try {
          await signUpWithProvider({
            email,
            token: accessToken,
            mediaType: "facebook",
            siteId,
          });
        } catch (err) {
          console.log(err);
          setError(err);
          setLoading(false);
        }
        return;
      }

      try {
        await signInWithProvider({
          email,
          facebookId: id,
          googleToken: "",
          googleClientId,
          siteId,
        });
      } catch (err) {
        console.log(err);
        setError(err);
        setLoading(false);
      }
    };

    const handleGoogleLogin = async (response: any) => {
      setLoading(true);
      const { email } = response.profileObj;
      const { tokenId } = response;

      if (includes(pathname, "sign-up")) {
        try {
          await signUpWithProvider({
            email,
            token: tokenId,
            mediaType: "google",
            siteId,
          });
        } catch (err) {
          console.log(err);
          setError(err);
          setLoading(false);
        }
        return;
      }

      try {
        await signInWithProvider({
          email,
          facebookId: "",
          googleToken: tokenId,
          googleClientId,
          siteId,
        });
      } catch (err) {
        console.log(err);
        setError(err);
        setLoading(false);
      }
    };

    const handleSocialLoginFailue = (response: any) => {
      console.log("failed social login", response);
      setLoading(false);
    };

    useEffect(() => {
      document.getElementsByClassName("facebook-login-btn")[0].innerHTML =
        "Facebook";
    });

    return (
      <div className="sign-up-using">
        <div className="sign-up-using-text">
          or {includes(pathname, "sign-up") ? "signup" : "login"} using:
        </div>
        <div className="links">
          <FacebookLogin
            appId={facebookAppId}
            autoLoad={false}
            onFailure={handleSocialLoginFailue}
            callback={handleFacebookLogin}
            cssClass="facebook-login-btn"
            fields="email"
            redirectUri={process.env.REACT_APP_FACEBOOK_REDIRECT_URL}
          />

          <GoogleLogin
            clientId={googleAppId}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                google
              </button>
            )}
            buttonText="Login"
            onSuccess={handleGoogleLogin}
            onFailure={handleSocialLoginFailue}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      </div>
    );
  };

export default SignInWithProviderComponent;
