import "./App.scss";
import { AuthProvider, DatabaseProvider, StorageProvider } from "./contexts";
import MainRouter from "./utilities/router";

const App = () => (
  <AuthProvider>
    <DatabaseProvider>
      <StorageProvider>
        <div className="app-main-container">
          <MainRouter />
        </div>
      </StorageProvider>
    </DatabaseProvider>
  </AuthProvider>
);

export default App;
