import React, {
  useContext,
  ReactChild,
  ReactChildren,
  useState,
  useEffect,
} from "react";
import firebase from "firebase";
import { auth, database } from "../../utilities/firebase";
import {
  CurrentUser,
  ProductCatalogueItemInterface,
  RegisterWithProvider,
  SignInInterface,
  SignInWithProvider,
  SignUpInterface,
  UserDataInterface,
} from "../../utilities/interfaces";
import api from "../../api";
import qs from "qs";
import { includes } from "lodash";

interface AuthContextInterface {
  currentUser: CurrentUser | null;
  signUp: Function;
  signIn: Function;
  signOut: Function;
  resetPassword: Function;
  reAthenticateUser: Function;
  updatePassword: Function;
  signInWithProvider: Function;
  signUpWithProvider: Function;
}

const AuthContext = React.createContext<AuthContextInterface>(
  {} as AuthContextInterface
);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({
  children,
}: {
  children: ReactChild | ReactChildren;
}) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState<boolean>(true);

  const signUp = async (values: SignUpInterface) => {
    const { email, password, confirmPassword } = values;

    return new Promise((resolve, reject) => {
      api
        .post(
          "/customer/register",
          {
            email,
            password,
            confirmPassword,
            phoneNumber: "",
            countryCode: "",
            idKey: "",
            oneTimePin: "",
            otpUniqueId: "",
            tfgcardnumber: "",
            linkCard: "",
            subscribeNewsletter: "",
            siteId: process.env.REACT_APP_MKM_SITE_ID,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(async (response: any) => {
          const { registerResponse } = response.data;
          const { success, errorMessage, idKey } = registerResponse;

          if (!success) {
            console.log(errorMessage);
            reject(errorMessage);
            return;
          }

          // get customer details
          try {
            const customer = await searchCustomer(idKey);
            resolve(customer);
          } catch {
            reject("An unkown error has occured, please try again!");
          }
        })
        .catch((err) => {
          console.log(err);
          reject("An unkown error has occured, please try again!");
        });
    });
  };

  const signInWithFirebase = (values: SignInInterface) => {
    const { email, password } = values;
    return new Promise((resolve, reject) => {
      auth
        .signInWithEmailAndPassword(email, password)
        .then(async (response) => {
          const { uid, photoURL } = response as any;
          const document = database.collection("users").doc(uid);
          database
            .collection("users")
            .doc(uid)
            .get()
            .then(async (doc) => {
              console.log("doc", doc.exists);
              if (doc.exists) {
                const firebaseUserData =
                  doc.data() as Array<ProductCatalogueItemInterface>;
                console.log("firebaseUserData", firebaseUserData);
              }
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  };

  const signIn = (values: SignInInterface) => {
    const { email, password, keepMeLoggedIn } = values;
    return new Promise((resolve, reject) => {
      api
        .post(
          `/login`,
          qs.stringify({
            email,
            password,
            countryCode: "",
            phoneNumber: "",
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(async (response: any) => {
          const { loginResponse } = response.data;
          const { success, message, idKey } = loginResponse;
          if (!success) {
            if (includes(message, "not found")) {
              try {
                const firebaseUserDetails = await signInWithFirebase({
                  email,
                  password,
                  keepMeLoggedIn,
                });
                console.log("firebaseUserDetails", firebaseUserDetails);
              } catch (err) {
                reject(err);
              }
              return;
            }
            reject(message);
            return;
          }

          // get customer details
          try {
            const customer = await searchCustomer(idKey);
            setKeepMeLoggedIn(keepMeLoggedIn);
            resolve(customer);
          } catch {
            reject("An unkown error has occured, please try again!");
          }
        })
        .catch((err) => {
          console.log(err);
          reject("An unkown error has occured, please try again!");
        });
    });
  };

  const signInWithProvider = (signInInfo: SignInWithProvider) => {
    return new Promise((resolve, reject) => {
      api
        .post("/customer/social/login", signInInfo, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (response: any) => {
          const { loginResponse } = response.data;
          const { onlineProfiles, success, errorMessage } = loginResponse;
          if (!success) {
            reject(errorMessage);
            return;
          }
          if (!onlineProfiles) {
            reject("No profile found, please try to signUp");
            return;
          }

          const idKey = onlineProfiles[0].idKey;
          const customer = await searchCustomer(idKey);
          setKeepMeLoggedIn(keepMeLoggedIn);
          resolve(customer);
        })
        .catch((err) => {
          console.log(err);
          reject("An unkown error has occured, please try again!");
        });
    });
  };

  const signUpWithProvider = (signUpInfo: RegisterWithProvider) => {
    return new Promise((resolve, reject) => {
      api
        .post("/customer/social/register", signUpInfo, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response: any) => {
          const { registerResponse } = response.data;
          const { errorMessage, success } = registerResponse;
          if (!success) {
            reject(errorMessage);
          }
          resolve(registerResponse);
          console.log(registerResponse);
        })
        .catch((err) => {
          console.log(err);
          reject("An unkown error has occured, please try again!");
        });
    });
  };

  const searchCustomer = (customerId: string | null) => {
    if (customerId == null) return;

    return new Promise((resolve, reject) => {
      api
        .get(
          "/customer",
          { idKey: customerId },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(async (response) => {
          const { customer }: any = response.data;
          const { firstName, lastName } = customer;
          const userData = {
            ...customer,
            displayName: `${firstName ? firstName : ""} ${
              lastName ? lastName : ""
            }`,
          };

          await firebasesignInAnonymously();
          setCurrentUser(userData);
          resolve(userData);

          // save user data to storage if
          window.sessionStorage.setItem(
            "currentUser",
            JSON.stringify(userData)
          );
        })
        .catch((err) => {
          console.log(err);
          reject("An unkown error has occured, please try again!");
        });
    });
  };

  const firebasesignInAnonymously = () => {
    return firebase.auth().signInAnonymously();
  };

  const reAthenticateUser = () => {};

  const signOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    setCurrentUser(null);
    return auth.signOut();
  };

  const resetPassword = (email: string) => {
    return auth.sendPasswordResetEmail(email);
  };

  const updatePassword = ({ password }: { password: string }) => {};

  const getUserDataFromSessionStorage = async () => {
    const userDataString = await window.sessionStorage.getItem("currentUser");
    setLoading(false);

    if (userDataString === null) return;
    const userData: CurrentUser = JSON.parse(userDataString);
    setCurrentUser(userData);
  };

  useEffect(() => {
    // get user data from session storage
    getUserDataFromSessionStorage();

    if (keepMeLoggedIn) return;
    window.onbeforeunload = () => {
      sessionStorage.clear();
      localStorage.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value: AuthContextInterface = {
    currentUser: currentUser,
    signUp: signUp,
    signIn: signIn,
    signOut: signOut,
    resetPassword: resetPassword,
    reAthenticateUser: reAthenticateUser,
    updatePassword: updatePassword,
    signInWithProvider: signInWithProvider,
    signUpWithProvider: signUpWithProvider,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : null}
    </AuthContext.Provider>
  );
};
