import { reduce } from 'lodash';
import * as React from 'react';
import { useSelectedProducts } from '../../contexts';
import './styles.scss';
import { useDatabase } from '../../contexts/database-context/index';
import { LoaderComponent } from '..';
import { size } from 'lodash';

interface IEstimatedOutfitCostProps {}

const EstimatedOutfitCost: React.FunctionComponent<IEstimatedOutfitCostProps> =
  () => {
    const [estimatedCost, setEstimatedCost] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(false);

    const { products } = useSelectedProducts();
    const { addProductsToBasket } = useDatabase();

    const updateBasket = async () => {
      setLoading(true);
      try {
        const basket = await addProductsToBasket();
        setLoading(false);
        console.log('basket', basket);

        // go to mkmsite for user to view basket
        window.open(
          'https://www.markham.co.za/myaccount/login.jsp?redirectLoginURL=/basket/basket.jsp',
          '_blank',
        );
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    React.useEffect(() => {
      const cost = reduce(products, (acc, obj) => acc + obj.product_price, 0);
      setEstimatedCost(cost);
    }, [products]);

    return (
      <div className='estimated-outfit-cost-container'>
        <div className='estimated-outfit-cost'>
          <div className='label'>
            <strong>Estimated</strong> Outfit cost
          </div>
          <div className='cost'>R {estimatedCost}</div>
          <button
            className='add-to-cart-button'
            onClick={(e) => {
              e.preventDefault();
              updateBasket();
            }}
          >
            <span>{size(products)}</span>
          </button>
        </div>
        {loading && (
          <LoaderComponent loading_text='Please wait while we add your items to your bag' />
        )}
      </div>
    );
  };

export default EstimatedOutfitCost;
