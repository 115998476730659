import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { Link } from 'react-router-dom';
import AvatarIcon from '../../../assets/icons/profile-icon.png';
import {
  LoaderComponent,
  OurMkmTextHeader,
  SignInWithProviderComponent,
  SubmitButton,
} from '../../../components';
import { FormFeedBack, useAuth } from '../../../contexts';
import { signInSchema } from '../../../utilities';
import { SignInInterface } from '../../../utilities/interfaces';
import IconEnvelop from '../../../assets/icons/icon-envelop.png';
import IconLock from '../../../assets/icons/icon-lock.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';

interface ISignInScreenProps {}

const SignInScreen: React.FunctionComponent<ISignInScreenProps> = (props) => {
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { signIn } = useAuth();

  const handleFormSubmit = async (values: SignInInterface) => {
    setLoading(true);
    try {
      await signIn(values);
    } catch (err) {
      setLoading(false);
      setError(err);
      formInk.isSubmitting = false;
    }
  };

  const initialValues: SignInInterface = {
    email: '',
    password: '',
    keepMeLoggedIn: false,
  };

  const formInk = useFormik({
    initialValues: initialValues,
    validationSchema: signInSchema,
    validateOnMount: false,
    onSubmit: (values: SignInInterface) => {
      handleFormSubmit(values);
      formInk.isSubmitting = true;
    },
  });

  const { values, errors, touched, isValid, dirty, submitForm } = formInk;

  if (loading) return <LoaderComponent />;

  return (
    <div className='sign-in-screen'>
      <FormikProvider value={formInk}>
        <div className='form'>
          <OurMkmTextHeader />
          <div className='avatar-icon'>
            <img src={AvatarIcon} alt='' />
          </div>
          <div className='wardrobe-text'>
            avatar <br />
            wardrobe
          </div>

          <Form>
            <div className='form-group'>
              <div className='form-field'>
                <Field
                  type='email'
                  name='email'
                  id='email'
                  placeholder='email:'
                  onFocus={() => {
                    if (error !== null) setError(null);
                  }}
                  className={
                    errors.email && touched.email ? 'input-error' : null
                  }
                />
                <div className='icon'>
                  <img src={IconEnvelop} alt='' />
                </div>
              </div>
              <ErrorMessage name='email' component='span' className='error' />
            </div>
            <div className='form-group'>
              <div className='form-field'>
                <Field
                  type='password'
                  name='password'
                  id='password'
                  placeholder='password:'
                  onFocus={() => {
                    if (error !== null) setError(null);
                  }}
                  className={
                    errors.password && touched.password ? 'input-error' : null
                  }
                />
                <div className='icon'>
                  <img src={IconLock} alt='' />
                </div>
              </div>
              <ErrorMessage
                name='password'
                component='span'
                className='error'
              />
            </div>
            <div className='form-group'>
              <div className='checkbox-container'>
                {values.keepMeLoggedIn ? (
                  <FontAwesomeIcon icon={faCheckCircle} />
                ) : (
                  <FontAwesomeIcon icon={faCircle} />
                )}
                <Field
                  type='checkbox'
                  id='keep-me-logged-in'
                  name='keepMeLoggedIn'
                />
                <label htmlFor='keep-me-logged-in'>Keep me logged in</label>
              </div>
            </div>
            <div className='form-group'>
              <SubmitButton
                title='Login'
                cssClass='form-submit sign-button'
                disabled={!(dirty && isValid && !loading)}
                loading={loading}
                action={submitForm}
              />
              <FormFeedBack errorMessage={error} successMessage='' />
            </div>

            <SignInWithProviderComponent
              setLoading={setLoading}
              setError={setError}
            />

            <div className='links'>
              <Link to='/auth/recover-passowrd'>
                Forgot password? <strong>Recover here</strong>
              </Link>
              <Link to='/auth/sign-up'>
                Don’t have an account? <strong>Sign Up here</strong>
              </Link>
            </div>
          </Form>
        </div>
      </FormikProvider>
    </div>
  );
};

export default SignInScreen;
