/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import fabric from "fabric";
import {
  ActiveCanvasElementInterface,
  CanvasSvgObjectInterface,
} from "../../../utilities/interfaces";
import { filter, includes, map, toLower } from "lodash";
import { useAuth, useDatabase, useStorage } from "../../../contexts";
import { useHistory } from "react-router-dom";
import { backgrounds, manican_bodies } from "../../../utilities/data";
import { removeKeyFromArrayOfObjects } from "../../../utilities";

interface IFabricCanvasProps {
  setEssentialAssetsLoaded: Function;
  activeCanvasElement: ActiveCanvasElementInterface | null;
  saveImageToProfile: boolean;
  setSaveImageToProfile: Function;
  setError: Function;
}

const FabricCanvas: React.FunctionComponent<IFabricCanvasProps> = ({
  setEssentialAssetsLoaded,
  activeCanvasElement,
  saveImageToProfile,
  setError,
  setSaveImageToProfile,
}) => {
  const [
    canvasElement,
    setCanvasElement,
  ] = React.useState<fabric.fabric.StaticCanvas>();
  const [elementsInCanvas, setElementsInCanvas] = React.useState<
    Array<ActiveCanvasElementInterface>
  >([]);
  const [manicanBody, setManicanBody] = React.useState<string>("");
  const [loadedTemplateTypes, setLoadedTemplateTypes] = React.useState<
    Array<string>
  >([]);

  const { currentUser } = useAuth();
  const { uploadAvatar } = useStorage();
  const { updateUserData, userData } = useDatabase();
  const history = useHistory();

  const findAndSetManicanBody = (avatar_head: string) => {
    if (includes(avatar_head, "C1")) {
      setManicanBody(manican_bodies.c1);
    } else if (includes(avatar_head, "C2")) {
      setManicanBody(manican_bodies.c2);
    } else if (includes(avatar_head, "C3")) {
      setManicanBody(manican_bodies.c3);
    } else if (includes(avatar_head, "C4")) {
      setManicanBody(manican_bodies.c4);
    } else {
      setManicanBody(manican_bodies.c5);
    }
  };

  const createAvatarCanvas = () => {
    const avatar_canvas = new fabric.fabric.StaticCanvas(
      "create-avatar-canvas",
      {
        preserveObjectStacking: true,
        height: 250,
        width: 250,
      }
    );

    setCanvasElement(avatar_canvas);
  };

  const saveToProfile = async () => {
    if (canvasElement) {
      const avatar = canvasElement.toDataURL({
        format: "png",
        quality: 1,
        multiplier: 4,
      });

      try {
        const url = await uploadAvatar(avatar);
        try {
          await updateUserData({
            manicanBody: manicanBody,
            manicanBackground: backgrounds[0].img, // set default background
            avatarState: removeKeyFromArrayOfObjects(
              elementsInCanvas,
              "canvas_item"
            ),
            photoUrl: url,
          });
          setSaveImageToProfile(false);
          history.push("/wardrobe/profile");
        } catch (err) {
          console.log(err);
          setSaveImageToProfile(false);
          setError(
            "An error occured while saving your avatar, please try again!"
          );
        }
      } catch (err) {
        console.log(err);
        setSaveImageToProfile(false);
        setError(
          "An error occured while saving your avatar, please try again!"
        );
      }
    }
  };

  let active_preload_elements: Array<ActiveCanvasElementInterface> = [];
  const addObjectsToCanvas = (
    activeCanvasElement: ActiveCanvasElementInterface | null,
    non_active_elements: Array<ActiveCanvasElementInterface>,
    is_preload: boolean
  ) => {
    if (
      canvasElement &&
      activeCanvasElement !== null &&
      activeCanvasElement.img_element
    ) {
      const active_element_image = activeCanvasElement?.img_element;

      fabric.fabric.loadSVGFromURL(active_element_image, (objects, options) => {
        const svg = fabric.fabric.util.groupSVGElements(
          objects,
          options
        ) as CanvasSvgObjectInterface;

        svg.scaleToHeight(canvasElement.getWidth() + 50);
        svg.scaleToHeight(canvasElement.getHeight() + 50);

        canvasElement
          .add(svg)
          .moveTo(svg, activeCanvasElement.z_index)
          .centerObject(svg);

        svg.id = activeCanvasElement.template_type;

        // set manican body
        if (toLower(activeCanvasElement.template_type) === "head") {
          findAndSetManicanBody(active_element_image);
        }

        // add templates to state to keep track of items in the canvas
        active_preload_elements.push({
          ...activeCanvasElement,
          canvas_item: svg,
        });

        setLoadedTemplateTypes([
          ...loadedTemplateTypes,
          activeCanvasElement.template_type,
        ]);

        if (is_preload) {
          setElementsInCanvas(active_preload_elements);
          checkForEssentialAssets(active_preload_elements);
          return;
        }

        const elements_in_canvas = [
          ...non_active_elements,
          {
            ...activeCanvasElement,
            canvas_item: svg,
          },
        ];

        setElementsInCanvas(elements_in_canvas);
        checkForEssentialAssets(elements_in_canvas);
      });
    }
  };

  const preloadCanvas = () => {
    if (userData) {
      const avatar_state = userData?.avatarState;
      map(avatar_state, (element) => {
        addObjectsToCanvas(element, elementsInCanvas, true);
      });
    }
  };

  React.useEffect(() => {
    if (canvasElement && activeCanvasElement !== null) {
      // find non active elements
      const non_active_elements: Array<ActiveCanvasElementInterface> = filter(
        elementsInCanvas,
        (element) => element.template_type !== activeCanvasElement.template_type
      );

      // remove objects from state when none is selected
      if (activeCanvasElement.img_element === "") {
        setElementsInCanvas(
          filter(
            elementsInCanvas,
            (element) =>
              element.template_type !== activeCanvasElement.template_type
          )
        );
      }

      // remove templates if they exist
      map(canvasElement?.getObjects(), (object: CanvasSvgObjectInterface) => {
        if (
          object.id !== undefined &&
          object.id === activeCanvasElement?.template_type
        ) {
          canvasElement?.remove(object);
        }
      });

      addObjectsToCanvas(activeCanvasElement, non_active_elements, false);
    }
  }, [canvasElement, activeCanvasElement]);

  const checkForEssentialAssets = (
    elements: Array<ActiveCanvasElementInterface>
  ) => {
    const templates_in_canvas = map(
      elements,
      (element) => element.template_type
    );

    setEssentialAssetsLoaded(
      includes(templates_in_canvas, "head") &&
        includes(templates_in_canvas, "nose") &&
        includes(templates_in_canvas, "eyes") &&
        includes(templates_in_canvas, "lips")
    ); // next button should be clickable when essential assets are loaded
  };

  React.useEffect(() => {
    // preload data on canvas if avatar has been created before
    preloadCanvas();
  }, [canvasElement]);

  React.useEffect(() => {
    if (saveImageToProfile) {
      saveToProfile();
    }
  }, [saveImageToProfile]);

  React.useEffect(() => {
    createAvatarCanvas();
  }, []);

  return (
    <div className="fabric-canvas">
      <div className="canvas-container">
        <canvas id="create-avatar-canvas"></canvas>
        <div className="user-name">{currentUser?.displayName}</div>
      </div>
    </div>
  );
};

export default FabricCanvas;
