/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import fabric from "fabric";
import { map, size } from "lodash";
import { useAuth, useSelectedProducts } from "../../contexts";
import "./styles.scss";
import {
  CanvasImageObjectInterface,
  UserDataInterface,
} from "../../utilities/interfaces";
import { LoaderComponent, ShareComponent } from "..";

interface IManicanCanvasProps {
  userData: UserDataInterface | null;
}

const ManicanCanvas: React.FunctionComponent<IManicanCanvasProps> = ({
  userData,
}) => {
  const [
    canvasElement,
    setCanvasElement,
  ] = React.useState<fabric.fabric.StaticCanvas>();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [uploadingShareImage, setUploadingShareImage] = React.useState<boolean>(
    false
  );

  const { products } = useSelectedProducts();
  const { currentUser } = useAuth();

  const createManicanCanvas = () => {
    const avatar_canvas = new fabric.fabric.StaticCanvas(
      "create-manican-canvas",
      {
        renderOnAddRemove: false,
        allowTouchScrolling: true,
        height: 600,
        width: 800,
      }
    );

    setCanvasElement(avatar_canvas);
  };

  const preloadManicanData = () => {
    if (canvasElement !== undefined) {
      setLoading(true);
      canvasElement.clear(); // remove existing objects from canvas before adding new to prevent duplication

      const user_avatar = userData?.photoUrl;
      if (userData !== null && user_avatar) {
        fabric.fabric.Image.fromURL(userData.manicanBackground, (img) => {
          const { width: img_width, height: img_height } = img;

          if (img_width !== undefined && img_height !== undefined) {
            canvasElement.setBackgroundImage(
              img,
              canvasElement.renderAll.bind(canvasElement),
              {
                scaleX: canvasElement.getWidth() / img_width,
                scaleY: canvasElement.getHeight() / img_height,
              }
            );
          }

          // add manican body
          fabric.fabric.loadSVGFromURL(
            userData.manicanBody,
            (objects, options) => {
              const manican_body_object = fabric.fabric.util.groupSVGElements(
                objects,
                options
              );

              manican_body_object.scaleToHeight(
                canvasElement.getHeight() / 1.25
              );
              if (manican_body_object.scaleY) {
                manican_body_object.set({
                  top:
                    canvasElement.getHeight() -
                    manican_body_object.getScaledHeight() -
                    20,
                });
              }
              canvasElement.centerObjectH(manican_body_object);

              // add manican head
              fabric.fabric.Image.fromURL(
                user_avatar,
                (img) => {
                  const avatar_object = img.scaleToWidth(
                    canvasElement.getWidth() / 6.4
                  );
                  const reposition_object_left =
                    canvasElement.getCenter().left -
                    avatar_object.getScaledWidth() / 2 +
                    1;

                  if (avatar_object.scaleY) {
                    avatar_object.set({
                      top: 27,
                      left: reposition_object_left,
                    });
                  }

                  // add manican shoes
                  fabric.fabric.Image.fromURL(
                    require("../../assets/manican-shoes/shoes_front.png")
                      .default,
                    (shoes) => {
                      shoes.scaleToHeight(canvasElement.getHeight() / 1.26);

                      shoes.set({
                        top:
                          canvasElement.getHeight() -
                          shoes.getScaledHeight() -
                          20,
                        left:
                          reposition_object_left - shoes.getScaledWidth() / 6,
                        name: "shoes",
                      });

                      // add manican head and body group
                      const manican_full_body_group = new fabric.fabric.Group([
                        manican_body_object,
                        avatar_object,
                        shoes,
                      ]);

                      if (size(products) === 0) {
                        canvasElement.centerObjectH(manican_full_body_group);
                        canvasElement.add(manican_full_body_group);
                        canvasElement.renderAll();
                        setLoading(false);
                        return;
                      }

                      // add manican clothing
                      const manican_clothing_items: Array<
                        fabric.fabric.Object | fabric.fabric.Group
                      > = [];
                      map(products, (product) => {
                        fabric.fabric.Image.fromURL(
                          product.avatar,
                          (item: any) => {
                            const product_object: CanvasImageObjectInterface = item;

                            product_object.scaleToHeight(
                              canvasElement.getHeight() / 1.25
                            );

                            product_object.set({
                              top:
                                canvasElement.getHeight() -
                                product_object.getScaledHeight() -
                                20,
                              name: product.product_category,
                            });

                            product_object.id = product.product_category;

                            manican_clothing_items.push(product_object);

                            if (
                              size(manican_clothing_items) === size(products)
                            ) {
                              // add all products after items have been looped
                              const manican_clothing_group = new fabric.fabric.Group(
                                [...manican_clothing_items]
                              );

                              manican_clothing_group.forEachObject(
                                (object, index, objects) => {
                                  if (object.name === "bottoms") {
                                    if (index > 0) object.moveTo(0);
                                  }

                                  if (object.name === "tops") {
                                    if (index > 1 && size(objects) > 1)
                                      object.moveTo(1);
                                  }

                                  if (object.name === "jackets & coats")
                                    object.moveTo(size(objects));
                                }
                              );

                              canvasElement.centerObjectH(
                                manican_clothing_group
                              );

                              canvasElement.add(manican_body_object);
                              canvasElement.add(shoes);
                              canvasElement.add(manican_clothing_group);
                              canvasElement.add(avatar_object);

                              manican_clothing_group.dirty = true;

                              canvasElement.requestRenderAll();

                              setLoading(false);
                            }
                          },
                          { crossOrigin: "anonymous" }
                        );
                      });
                    }
                  );
                },
                { crossOrigin: "anonymous" }
              );
            }
          );
        });
      }
    }
  };

  React.useEffect(() => {
    if (userData !== null && currentUser) preloadManicanData();
  }, [canvasElement, products]);

  React.useEffect(() => {
    createManicanCanvas();
    return function cleanup() {
      // clear references to canvas and elements on unmount
      canvasElement?.dispose();
    };
  }, []);

  return (
    <>
      <div className="manican-container fabric-canvas">
        <div className="canvas-container">
          <canvas id="create-manican-canvas"></canvas>
          {(loading || uploadingShareImage) && (
            <LoaderComponent
              loading_text={
                uploadingShareImage
                  ? "Please wait while we prepare your avatar"
                  : "Please wait while we dress your avatar"
              }
            />
          )}
          {!loading && (
            <ShareComponent
              canvasElement={canvasElement}
              setLoading={setUploadingShareImage}
              loading={uploadingShareImage}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ManicanCanvas;
