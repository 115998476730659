import {
  filter,
  includes,
  isEmpty,
  isNull,
  isUndefined,
  map,
  omitBy,
  size,
  toUpper,
} from "lodash";
import * as React from "react";
import { useAuth } from "..";
import api from "../../api";
import { database } from "../../utilities/firebase";
import {
  Cart,
  ProductCatalogueItemInterface,
  UserDataInterface,
} from "../../utilities/interfaces";
interface DatabaseContextInterface {
  getProductList: Function;
  productList: Array<ProductCatalogueItemInterface>;
  setProductlist: Function;
  productListLoading: boolean;
  gettingUserData: boolean;
  getUserData: Function;
  addProductsToBasket: () => Promise<unknown>;
  updateUserData: Function;
  userData: UserDataInterface | null;
  cart: Cart | undefined;
}

const DatabaseContext = React.createContext<DatabaseContextInterface>(
  {} as DatabaseContextInterface
);

export const useDatabase = () => React.useContext(DatabaseContext);
interface IDatabaseProviderProps {}

export const DatabaseProvider: React.FunctionComponent<IDatabaseProviderProps> =
  ({ children }) => {
    const [productList, setProductlist] = React.useState<
      Array<ProductCatalogueItemInterface>
    >([]);
    const [userData, setUserData] =
      React.useState<UserDataInterface | null>(null);
    const [productListLoading, setPoductListLoading] =
      React.useState<boolean>(false);
    const [gettingUserData, setGettingUserData] = React.useState<boolean>(true);
    const [cart, setCart] = React.useState<Cart | undefined>();

    const { currentUser } = useAuth();

    const getUserData = () => {
      return new Promise<UserDataInterface>((resolve, reject) => {
        database
          .collection("users")
          .doc(currentUser?.idKey)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              const data = doc.data() as UserDataInterface;
              setUserData(data);
              await getItemsInBasket();
              resolve(data);
            } else {
              reject("user data not found");
            }
            setGettingUserData(false);
          });
      });
    };

    const getProductList = () => {
      setPoductListLoading(true);
      database
        .collection("products-list")
        .get()
        .then((snapshot) => {
          setPoductListLoading(false);
          const data = snapshot.docs.map((doc) =>
            doc.data()
          ) as Array<ProductCatalogueItemInterface>;
          setProductlist(data);
        })
        .catch((err) => {
          setPoductListLoading(false);
          console.log("no products", err);
        });
    };

    const updateUserData = (data: UserDataInterface) => {
      const document = database.collection("users").doc(currentUser?.idKey);
      // add new data if it exists else update
      return document.get().then((doc) => {
        if (doc.exists) {
          return document
            .update({
              ...omitBy(data, isUndefined),
            })
            .then(async () => {
              try {
                const user_data = await getUserData();
                setUserData(user_data);
              } catch (err) {
                console.log(err);
              }
            });
        } else {
          return document
            .set({
              waistSize: "32",
              shirtSize: "S",
              ...omitBy(data, isEmpty || isUndefined || isNull),
            })
            .then(async () => {
              try {
                const user_data = await getUserData();

                setUserData(user_data);
              } catch (err) {
                console.log(err);
              }
            });
        }
      });
    };

    const getProduct = (productId: string) => {
      return new Promise((resolve, reject) => {
        api
          .get(
            "/product",
            {
              productId: productId,
              siteId: process.env.REACT_APP_MKM_SITE_ID,
            },
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((response) => {
            const { product } = response.data as any;
            resolve(product);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    };

    const getProductSku = (
      productId: string,
      categorySize: string | undefined
    ) => {
      return new Promise(async (resolve, reject) => {
        const product: any = await getProduct(productId);
        const size = filter(product.sizes, (item) =>
          includes(toUpper(item.name), toUpper(categorySize))
        )[0];

        api
          .get(
            "/product",
            {
              productId: productId,
              selectedSize: size ? size : "",
              siteId: process.env.REACT_APP_MKM_SITE_ID,
            },
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((response) => {
            const { product } = response.data as any;
            resolve(product);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    };

    const addProductToBasket = ({
      productId,
      catalogRefId,
    }: {
      productId: string;
      catalogRefId: string;
    }) => {
      return new Promise((resolve, reject) => {
        api
          .post(
            "/bag/item",
            {},
            {
              headers: { "Content-Type": "application/json" },
              data: {
                productId: productId,
                catalogRefId: catalogRefId,
                quantity: 1,
                siteId: process.env.REACT_APP_MKM_SITE_ID,
                idKey: currentUser?.idKey,
              },
            }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    };

    const addProductsToBasket = () => {
      return new Promise(async (resolve, reject) => {
        const getProductSkuPromises: Array<Promise<unknown>> = [];
        const addProductsToBasketPromises: Array<Promise<unknown>> = [];

        // delete current items from basket before adding new
        await removeItemsFromBasket();

        // loop Through products and get sku`s
        map(userData?.products, (product) => {
          //const categorySize = includes(product.product_category, 'bottom') ? userData?.waistSize : userData?.shirtSize as string;
          getProductSkuPromises.push(
            getProductSku(product.product_id, userData?.shirtSize)
          );
        });

        // wait for all product sku to be fetched
        Promise.all(getProductSkuPromises)
          .then((response) => {
            // loop products with sku and add to basket
            map(response, (product: any) => {
              addProductsToBasketPromises.push(
                addProductToBasket({
                  productId: product.productId,
                  catalogRefId: product.sku,
                })
              );

              // run addProductsToBasketPromises array when all items are added
              if (size(addProductsToBasketPromises) === size(response)) {
                addItemsToBasket();
              }
            });
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });

        const addItemsToBasket = () => {
          // wait for products to be added to basket, only resolve when all the products are in the basket
          Promise.all(addProductsToBasketPromises)
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        };
      });
    };

    const getItemsInBasket = () => {
      return new Promise((resolve, reject) => {
        api
          .get(
            "/bag",
            { idKey: currentUser?.idKey },
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((response: any) => {
            setCart(response.data.basketDetails);
            resolve(response.data.basketDetails);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    };

    const removeItemFromBasket = (itemId: string) => {
      return new Promise(async (resolve, reject) => {
        api
          .delete(
            "/bag/item",
            {},
            {
              headers: { "Content-Type": "application/json" },
              data: { itemId: itemId, idKey: currentUser?.idKey },
            }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    };

    const removeItemsFromBasket = () => {
      return new Promise(async (resolve, reject) => {
        const itemsInBasketPromises: Array<Promise<unknown>> = [];

        const basket: any = await getItemsInBasket();
        if (basket && size(basket.items) === 0) {
          resolve("no items in basket");
        }

        const deleteAllItems = () => {
          Promise.all(itemsInBasketPromises)
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        };

        map(basket.items, (item) => {
          itemsInBasketPromises.push(removeItemFromBasket(item.id));

          // run when all items have been mapped
          if (size(itemsInBasketPromises) === size(basket.items)) {
            deleteAllItems();
          }
        });
      });
    };

    const value: DatabaseContextInterface = {
      getUserData: getUserData,
      userData: userData,
      updateUserData: updateUserData,
      getProductList: getProductList,
      productList: productList,
      setProductlist: setProductlist,
      addProductsToBasket: addProductsToBasket,
      productListLoading: productListLoading,
      gettingUserData: gettingUserData,
      cart: cart,
    };

    React.useEffect(() => {
      if (currentUser === null) return;
      getProductList();
      getUserData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <DatabaseContext.Provider value={value}>
        {children}
      </DatabaseContext.Provider>
    );
  };
