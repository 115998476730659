import { create } from "apisauce";

const API_KEY = process.env.REACT_APP_MKM_API_KEY;
const API_URL =
  process.env.NODE_ENV === "development"
    ? "/api"
    : process.env.REACT_APP_MKM_API_URL;

// define the api
const api = create({
  baseURL: API_URL,
  headers: {
    "x-api-key": API_KEY,
  },
});

export default api;
